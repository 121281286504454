import Navbar from "./components/Navbar";
import ChangeQuantity from "./components/ChangeName";

const App = () => {
  return (
    <div className="bg-black min-h-screen">
      <Navbar />
      <img src="https://bafybeiadrlorlewgbfp6eoi75bvlpctbcqay5wq525yrzrpgncm3sptojq.ipfs.nftstorage.link/mintbanner2.png" width="100%" />
      <br /><br /><br />
      <ChangeQuantity />
         <center>
          <font 
            color="#FFC824"
          > 
          <br/><br/>
          <sub>
          Crowdsale Contract : KT1EZyeDPT5Jnk2626u1tk8k4wRV9cAGU2Yh
          <br />
          Provenance Hash : QmX4extb28Lo4VbZw1ZJ54bCAGvasio4ykHWjCkCuUhi5L
          </sub>
          </font>
          </center>
     </div> 
  );
};

export default App;
