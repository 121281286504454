import { useState } from "react";
import { changeQuantity } from "../utils/wallet";

export default function ChangeQuantity(props) {
  const [quantity, setQuantity] = useState("");
  
  return (
  
    <div>
      <div>
      <center>
      <input
        name="name"
        type="number"
        min="1"
        name="name"
        style={{"width": "200px"}}
        placeholder="Number of FEARZ"
        onChange={(e) => {
          setQuantity(e.target.value);
        }}
        value={quantity}
      />
      &ensp;
      <button
        onClick={() => {
          changeQuantity(quantity);
        }}
        className="border-2 mt-2 border-yellow-400 bg-black px-6 py-3 rounded-lg text-xl font-semibold uppercase text-white cursor-pointer"
      >
        <font color="#FFC824">
        MINT NOW!
        </font>
      </button>
      </center>
      </div>

<div id="result">
<center><font color="#FFC824">703/5000 MINTED</font></center> 
</div>
    </div>
  );
}
